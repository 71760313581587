import React, {Fragment} from 'react';
import {ThemeProvider} from "styled-components";
import {CremaTheme} from "../../theme";
import SEO from "../../components/seo";
import {ResetCSS} from "../../common/assets/css/style";
import {ContentWrapper, GlobalStyle} from "../../containers/sassModern.style";
import Sticky from "react-stickynode";
import {DrawerProvider} from "../../common/contexts/DrawerContext";
import Navbar from "../../containers/Navbar";
import BannerSection from "../../containers/Banner";
import BestInClass from "../../containers/BestInClass";
import Application from "../../containers/Application";
import OtherPages from "../../containers/OtherPages";
import Customization from "../../containers/Customization";
import SelectedMenuSection from "../../containers/SelectedMenuSection";
import PowerfulServer from "../../containers/PowerfulServer";
import LanguageSection from "../../containers/LanguageSection";
import Authorization from "../../containers/Authorization";
import Technology from "../../containers/Technology";
import BuildStunning from "../../containers/BuildStunning";
import {graphql, useStaticQuery} from "gatsby";
import Footer from "../../containers/Footer";
import Testimonials from "../../containers/Testimonials";
import AuthorizationImage from "../../images/antd-login.png";
import LanguageImage from "../../images/language/language-ant.png";
import Features from "../../containers/Features";
import Box from "../../elements/Box";
import ContactUs from "../../containers/ContactUs";

const CremaAnt = () => {

  const data = useStaticQuery(graphql`
    query {
      hipsterJson {
        antMenuItems {
          label
          path
          offset
        }
        brandAnt {
          id
          srcImg
        }
        antBestInClass {
          layout {
            id
            srcImg
            title
            variant {
              id
              name
              url
            }
          }
        }
        antApplication {
          appPanel {
            id
            srcImg
            title
            menuId
            variant {
              id
              name
              url
            }
          }
          appMenu {
            id
            label
            path
            offset
          }
        }
        antOtherPages {
          id
          srcImg
          title
          variant {
            id
            name
            url
          }
        }
        antColorCustomization {
          customizationList {
            id
            srcImg
            title
          }
          colorCustomization {
            id
            srcImg
            title
          }
        }
        antSelectedMenu {
          id
          srcImg
          title
          variant {
            id
            name
            url
          }
        }
        
      }
    }
  `);

  return (
    <ThemeProvider theme={CremaTheme}>
      <Fragment>
        <SEO
          title="Ant Design Template"
          description="Crema React is available with Ant Design"
          keywords={[
            "Material Admin Theme",
            "MUI Admin Theme",
            "Admin Theme with TypeScript",
            "Ant Design Admin Theme",
            "Bootstrap Admin Theme",
            "React Admin Theme Typescript",
            "React Admin Theme Context API",
            "Redux and Context Api Template",
            "Pure React hooks based Template",
            "Academic Dashboard",
            "Analytics Dashboard",
            "Health Care Dashboard",
            "CRM Dashboard",
            "Crypto Dashboard",
            "Ecommerce Dashboard",
            "Email Application",
            "Calendar Application",
            "TODO/Task Application",
            "Scrum board Application",
            "Chat Application",
            "Profile/Wall Application",
            "Google Map",
            "React Calendar",
            "Firebase",
            "Aws Cognito",
            "Auth0",
            "JWT Auth",
          ]}
        />

        <ResetCSS/>
        <GlobalStyle/>

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar menuItems={data.hipsterJson.antMenuItems}/>
            </DrawerProvider>
          </Sticky>

          <div id="banner_section">
            <BannerSection bannerBrand={data?.hipsterJson?.brandAnt} url='https://ant-cra.cremawork.com/' content='Start a new project with Crema Ant + Styled Component'/>
          </div>

          <div id="layout">
            <Features type="Ant"/>

            <BestInClass bestInClass={data?.hipsterJson?.antBestInClass}/>
          </div>
          <div id="application">

            <Application application={data?.hipsterJson?.antApplication}/>

            <OtherPages otherPages={data?.hipsterJson?.antOtherPages}/>
          </div>

          <div id="customization">
            <Customization customization={data?.hipsterJson?.antColorCustomization}/>

            <SelectedMenuSection selectedMenu={data?.hipsterJson?.antSelectedMenu}/>
          </div>

          <div id="features">
            <PowerfulServer/>

            <LanguageSection image={LanguageImage}/>

            <Authorization authorizationImage={AuthorizationImage}/>

            <Technology/>
          </div>

          <Testimonials/>
          <BuildStunning/>

          <Box as="section" id="contact_us_section">
            <ContactUs/>
          </Box>
          <Footer/>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default CremaAnt;